<template>
  <div>
    
    <b-modal id="deleteAuftragConfirm"
             title="Löschen bestätigen"
             header-bg-variant="dark" @ok="confirmedDelete">
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()" class="btn btn-default btn-form">Abbrechen</b-button>
        <b-button variant="primary" @click="ok()" class="btn btn-default btn-form">Bestätigen</b-button>
      </template>
      <div>
        <div class="my-4">
          <p>Bitte bestätigen Sie, dass Sie diesen Auftrag löschen möchten.</p>
        </div>
      </div>
    </b-modal>
    
    <section>
      <Hero>
        <div v-if="loadingHeader">
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Die Auftragsdaten werden geladen...</strong>
          </div>
        </div>
        <div v-else-if="unauthorized">
          <div class="alert alert-warning" role="alert">
            <p>Sie haben hierfür keine Berechtigung.</p>
          </div>
        </div>
        <div v-else-if="notfound">
          <div class="alert alert-warning" role="alert">
            <p>Dieser Auftrag konnte nicht ermittelt werden bzw. Sie haben keinen Zugriff auf diesen Fonds.</p>
          </div>
        </div>
        <div v-else-if="servererror">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p v-if="error" class="fs-7">{{ error }}</p>
          </div>
        </div>
        <div v-else>
          <div class="container">
            <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Auftragsstatus'"></HeadText>
          </div>
          <div v-if="deleteDone" class="container pt-3 pl-0 pr-0">
            <div>
              <div v-if="this.auftragsHeaderData.auftragsart === 'Kauf'" class="alert alert-success" role="alert">
                <p>Die Löschung Ihres Kaufauftrags wurde erfolgreich aufgenommen!</p>
              </div>
              <div v-else class="alert alert-success" role="alert">
                <p>Die Löschung Ihres Verkaufsauftrags wurde erfolgreich aufgenommen!</p>
              </div>
            </div>
          </div>
          <div class="container bg-light pt-3 px-3" >
            <b-row>
              <b-col md="6" class="text-left">
                <p>
                  In der Detailansicht sehen Sie, in welchem Stadium sich Ihr Auftrag aktuell befindet.<br/><br/>
                </p>
                <p>
                  Sobald Sie einen Auftrag online erteilt haben, wird dieser am folgenden Handelstag im Menüpunkt 
                  „Auftragsstatus“ eingestellt. Bitte bewahren Sie Ihre Auftragsbestätigung gut auf – 
                  die jeweilige Auftragsnummer ist Identifikation für Ihren Auftrag.
                </p>
              </b-col>
              <b-col md="6" class="text-left">
                <FdbContact></FdbContact>
              </b-col>
            </b-row>
          </div>
          <div class="container mt-4 text-left">
            <h5>Ihr {{ this.auftragsHeaderData.auftragsart }}auftrag</h5>
          </div>
          <div class="container container-header mt-1 p-3">
            <b-row>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Auftragsnummer</div>
                    <span class="lead font-weight-bold h4-color"><span v-if="this.auftragsHeaderData.makler === 'D'">{{ this.auftragsHeaderData.makler }}</span>{{ this.auftragsHeaderData.auftragNr }}</span>
                  </div>
                </div>
              </b-col>
              <b-col md="10">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Fondsname</div>
                    <span class="lead font-weight-bold h4-color">
                      <router-link :to="{name: 'fondsdetails', params: {client: client, id: this.auftragsHeaderData.trancheId}}">
                        {{ this.auftragsHeaderData.anbietername }} - {{ fondsNameWithZusatz( this.auftragsHeaderData ) }}
                      </router-link>
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row class="mt-3">
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Limit</div>
                    <span class="lead font-weight-bold h4-color">{{ formatPercent( this.auftragsHeaderData.limit ) }} %</span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Beauftr. Nominale / Nominale</div>
                    <span class="lead font-weight-bold h4-color fs-10-md-up">
                      {{ formatPrice( this.auftragsHeaderData.beauftragteNominale.betrag ) }} {{ this.auftragsHeaderData.beauftragteNominale.waehrung }} 
                      / 
                      {{ formatPrice( this.auftragsHeaderData.nominale.betrag ) }} {{ this.auftragsHeaderData.nominale.waehrung }} 
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Geld / Kauf</div>
                    <span class="lead font-weight-bold h4-color" v-if="this.auftragsHeaderData.geldKauf">{{ formatPercent( this.auftragsHeaderData.geldKauf ) }} %</span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Brief / Verkauf</div>
                    <span class="lead font-weight-bold h4-color" v-if="this.auftragsHeaderData.briefVerkauf">{{ formatPercent( this.auftragsHeaderData.briefVerkauf ) }} %</span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Letzter Kurs</div>
                    <span class="lead font-weight-bold h4-color" v-if="this.auftragsHeaderData.letzterKurs">{{ formatPercent( this.auftragsHeaderData.letzterKurs ) }} %</span>
                  </div>
                </div>
              </b-col>
              <b-col md="2">
                <div class="container-col-bg h-100 text-left p-2">
                  <div>
                    <div class="container-label">Datum letzter Kurs</div>
                    <span class="lead font-small-md font-weight-bold h4-color" v-if="this.auftragsHeaderData.datum">{{ this.auftragsHeaderData.datum }}</span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        
        <div class="container bg-light mt-3" v-if="!unauthorized && !notfound && !servererror && !loadingHistory">

          <b-row v-if="this.readOnly" class="pt-3">
            <b-col md="12">
              <b-alert show variant="warning">
                Bitte beachten Sie, dass momentan die Preisfeststellungen im Rahmen des
                täglichen Bietverfahrens stattfinden. Orderänderungen sind erst nach Abschluss des Bietverfahrens möglich.
              </b-alert>
            </b-col>
          </b-row>
          
          <b-row v-if="this.auftragsHeaderData && !this.auftragsHeaderData.role.directlyConnected" class="pt-3">
            <b-col md="12">
              <b-alert show variant="info" v-if="this.auftragsHeaderData.role.id > 50">
                Sie sind als sichtberechtigter {{ this.auftragsHeaderData.role.name }} an diesem Auftrag beteiligt. 
                Nur der tatsächliche Auftraggeber darf Änderungen vornehmen.
              </b-alert>
              <b-alert show variant="info" v-else>
                Sie sind als sichtberechtigter Kooperationspartner an diesem Auftrag beteiligt. Nur der tatsächliche 
                Auftraggeber darf Änderungen vornehmen.
              </b-alert>
            </b-col>
          </b-row>

          <b-row v-if="this.auftragsHeaderData && this.auftragsHeaderData.role.directlyConnected && this.auftragsHeaderData.role.id !== 60" class="pt-3">
            <b-col md="12">
              <b-alert show variant="info" v-if="this.auftragsHeaderData.role.id > 50">
                Sie sind als {{ this.auftragsHeaderData.role.name }} an diesem Auftrag beteiligt.
                Nur der Auftraggeber darf Änderungen vornehmen.
              </b-alert>
              <b-alert show variant="info" v-else>
                Sie sind als Kooperationspartner an diesem Auftrag beteiligt. Nur der Auftraggeber darf Änderungen 
                vornehmen.
              </b-alert>
            </b-col>
          </b-row>

          <b-tabs content-class="mt-3" nav-class="pt-3" pills>

            <b-tab title="Aktueller Status" active v-if="this.auftragsHistoryData">
              <div class="tableCard mb-5">
                <b-row>
                  <b-col cols="12" md="6">
                    <strong>Status: {{ this.auftragsHistoryData.auftragsstatus }}, 
                      seit {{ this.auftragsHistoryData.statusseit }}</strong>
                  </b-col>
                  <b-col cols="12" md="6">
                    <strong>Ablaufdatum: {{ this.auftragsHistoryData.ablauf }}</strong>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    Im Handel: {{ this.auftragsHistoryData.freigabe }}
                  </b-col>
                  <b-col cols="12" md="6">
                    Auftrag eingegangen: {{ this.auftragsHistoryData.beginn }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    Teilausführung: <span v-if="this.auftragsHistoryData.teilausfuehrbar">ja</span><span v-else>nein</span>
                    <span v-if="this.auftragsHistoryData.stueckelung">, {{ formatPrice(this.auftragsHistoryData.stueckelung.betrag) }} {{ this.auftragsHistoryData.stueckelung.waehrung }}</span>
                  </b-col>
                  <b-col cols="12" md="6">
                    &nbsp;
                  </b-col>
                </b-row>
                <b-row v-if="this.auftragsHeaderData && this.auftragsHeaderData.editable">
                  <b-col cols="12" md="12">
                    <span class="pr-3">

                      <a v-if='!this.readOnly && this.getAuftragType === "Verkauf"'
                                  class="btn btn-default"
                                  @click="auftragEditWizardUrl('VERKAUF')">
                        Ändern
                      </a>
                      <a v-else-if='!this.readOnly && this.getAuftragType === "Kauf"'
                         class="btn btn-default"
                         @click="auftragEditWizardUrl('KAUF')">
                        Ändern
                      </a>

                      <a v-else class="btn btn-default disabled" disabled="disabled">Ändern</a>

                    </span>

                    <span>
                      <a class="btn btn-default" 
                         v-bind:class="[{disabled : this.readOnly}]" 
                         @click="deleteAuftragConfirmation">
                        Löschen
                      </a>
                    </span>
                  </b-col>
                </b-row>
                <b-row v-if="deleteError401">
                  <b-col cols="12" md="12">
                    <div class="alert alert-danger">
                      Ihre Sitzung ist abgelaufen.
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="deleteError403">
                  <b-col cols="12" md="12">
                    <div class="alert alert-danger">
                      Es ist ein Fehler aufgetreten.
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="deleteError404">
                  <b-col cols="12" md="12">
                    <div class="alert alert-danger">
                      Dieser Auftrag ist nicht vorhanden.
                    </div>
                  </b-col>
                </b-row>
                <b-row v-if="deleteError451">
                  <b-col cols="12" md="12">
                    <div class="alert alert-danger">
                      Ein Bietverfahren läuft gerade, Auftragsänderungen sind nicht erlaubt.
                    </div>
                  </b-col>
                </b-row>
                <div v-if="deleteLoading" class="text-center my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Ihr Auftrag wird gelöscht...</strong>
                </div>
              </div>
            </b-tab>
            
            <b-tab title="Änderungsverlauf" class="mb-3" v-if="this.auftragsHistoryData">
              <div class="tableCard" v-for="(vorgang,index) in this.auftragsHistoryData.vorgaenge" :key="index">
                <b-row>
                  <b-col cols="12" md="6">
                    <strong>Datum: {{ vorgang.datum }}</strong>
                  </b-col>
                  <b-col cols="12" md="6">
                    <strong>Vorgang: {{ vorgang.vorgangsart }}</strong>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    Limit: {{ formatPercent( vorgang.limit ) }} %
                  </b-col>
                  <b-col cols="12" md="6">
                    Nominale: {{ formatPrice( vorgang.nominale.betrag ) }} {{ vorgang.nominale.waehrung }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    Ablaufdatum: {{ vorgang.ablaufDatum }}
                  </b-col>
                  <b-col cols="12" md="6">
                    Beauftragte Nominale: {{ formatPrice( vorgang.beauftragteNominale.betrag ) }} {{ vorgang.beauftragteNominale.waehrung }}
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12" md="6">
                    Auftrag eingegangen: {{ vorgang.eingangsDatum }}
                  </b-col>
                  <b-col cols="12" md="6">
                    Teilausführung: <span v-if="vorgang.teilausfuehrbar">ja</span><span v-else>nein</span>
                    <span v-if="vorgang.stueckelung">, {{ vorgang.stueckelung.betrag }} {{ vorgang.stueckelung.waehrung }}</span>
                  </b-col>
                </b-row>
              </div>
            </b-tab>

            <b-tab title="Vermittlungen" v-if="this.auftragsHeaderData">
              <div class="tableCard">
                <VermittlungenOfAuftragListTableMobile :auftragId="this.auftragsHeaderData.id" v-if="mobileViewport"></VermittlungenOfAuftragListTableMobile>
                <VermittlungenOfAuftragListTable :auftragId="this.auftragsHeaderData.id" v-else></VermittlungenOfAuftragListTable>
              </div>
            </b-tab>

            <b-tab title="Dokumente">
              <div class="tableCard text-left p-3">
                <p v-if="!this.docsDownload">Es sind keine Dokumente zum Auftrag vorhanden.</p>
                <div v-else>
                  <table aria-colcount="2" class="table b-table table-striped table-bordered">
                    <tbody role="rowgroup">
                      <tr v-for="(document,index) in documentData.content" :key="index">
                        <td class="col-30-percent">
                          {{formatDate( document.datum )}}
                        </td>
                        <td class="col-70-percent">
                          <b-link @click="download( document )" rel="download">
                            <strong v-if="document.downloaded === 'false'">{{ document.beschreibung }}</strong>
                            <span v-else>{{ document.beschreibung }}</span>&nbsp;
                            <i class="fas fa-file-download"></i>
                          </b-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-tab>

            <b-tab title="Notiz" v-if="this.auftragsHeaderData && this.auftragsHeaderData.canCreateNote">
              <div v-if="this.hasNote" class="tableCard note text-left p-3">
                <p>{{formatDateTime(this.note.lastUpdated)}}</p>
                <p><strong>{{this.note.title}}</strong></p>
                <p class="mb-5">{{this.note.content}}</p>
                <a @click="addNote(auftragsHeaderData.id)" class="btn btn-default mr-3 mb-2">Notiz ändern</a>
                <a @click="deleteNote(auftragsHeaderData.id)" class="btn btn-danger mb-2">Notiz löschen</a>
                <p class="pt-3">Bitte beachten Sie: Notizen sind nur für Sie sichtbar.</p>
              </div>
              <div v-else class="tableCard text-left p-3">
                <p class="mb-5">Es ist keine Notiz zum Auftrag hinterlegt.</p>
                <a @click="addNote(auftragsHeaderData.id)" class="btn btn-default">Notiz anlegen</a>
                <p class="pt-3">Bitte beachten Sie: Notizen sind nur für Sie sichtbar.</p>
              </div>
              <NoteDetails v-bind:objectId="objectId" v-bind:objectType="'AUFTRAG'" v-bind:hasNote="this.hasNote" @changeNoteState="setNoteState($event)" v-show="false"></NoteDetails>
            </b-tab>
          </b-tabs>
        </div>
        
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FdbContact from "@/components/FdbContact";
import axios from "axios";
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import NoteDetails from "@/components/notes/NoteDetails"
import VermittlungenOfAuftragListTable from "../components/vermittlung/VermittlungenOfAuftragListTable";
import VermittlungenOfAuftragListTableMobile from "../components/vermittlung/VermittlungenOfAuftragListTableMobile";
import router from "../router";
import store from "../store";
import {mapGetters} from "vuex";

export default {
  name: "Auftragsdetails",
  components: {Hero, HeadText, FdbContact, NoteDetails, VermittlungenOfAuftragListTable,VermittlungenOfAuftragListTableMobile},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
    }
  },
  data: () => ({
    auftragsHeaderData: null,
    auftragsHistoryData: null,
    error: null,
    auftragDeleted: false,
    loadingHeader: true,
    loadingHistory: true,
    notfound: false,
    unauthorized: false,
    servererror: false,
    docsDownload: false,
    objectId: null,
    objectType: 'AUFTRAG',
    hasNote: false,
    noteData: '',
    documentData: {},
    refreshDocumentData: 0,
    initialDocumentCount: undefined,
    latestDocumentCount: undefined,
    note: {
      lastUpdated: '',
      title: '',
      content: '',
    },
    readOnly: false,
  }),
  created() {
    this.fetchData();
  },
  watch: {
    $route: function() {
      this.initialDocumentCount = undefined;
      this.latestDocumentCount = undefined;
      this.fetchData();
    },
    noteData: function() {
      if (this.noteData) {
        this.note.lastUpdated = this.noteData.lastUpdated;
        this.note.title = this.noteData.title;
        this.note.content = this.noteData.content;
      }
    },
    refreshDocumentData: function() {
      this.$nextTick(function () {
        this.triggerDocumentRefresh( this.$route.params.id );
      });
    }
  },
  computed: {
    ...mapGetters('auftragswizard', ['deleteLoading','deleteError401','deleteError403','deleteError404','deleteError451','deleteDone']),

    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
    token() {
      return this.$store.getters["auth/getToken"]
    },
    getAuftragType() {
      return this.auftragsHeaderData.auftragsart;
    }
  },
  methods: {
    minusMinutes( minutes ) {
      Date.prototype.minusMinutes = function() {
        let date = new Date(this.valueOf());
        date.setMinutes(date.getMinutes() - minutes);
        return date;
      }
      return new Date().minusMinutes();
    },
    calcDocumentRefresh() {
      // If the last status change was less than 5 minutes ago, trigger a refresh unless we've already got "more" documents
      
      let moreDocsThanToStart = this.initialDocumentCount && this.initialDocumentCount !== this.latestDocumentCount;
      // console.log( 'moreDocsThanToStart: ' + moreDocsThanToStart + ' (' + this.initialDocumentCount + ' vs. ' + this.latestDocumentCount + ')' );
      if ( moreDocsThanToStart ) {
        return;
      }
      if(!this.auftragsHeaderData) {
        this.refreshDocumentData++;
        return;
      }

      // "dateTimeLastStatusChange": "14.02.2023 14:07:04"
      let bigParts = this.auftragsHeaderData.dateTimeLastStatusChange.split( ' ' );
      let dateParts = bigParts[0].split( '.' );
      let timeParts = bigParts[1].split( ':' );
      let dateLastChange = new Date( dateParts[2], dateParts[1] - 1, dateParts[0], timeParts[0], timeParts[1], timeParts[2] );
      let tenMinutesAgo = this.minusMinutes( 10 );
      if ( dateLastChange.getTime() > tenMinutesAgo.getTime() ) {
        // Incrementing this will trigger the watch function and call triggerDocumentRefresh
        // console.log( 'Set refreshDocumentData to ' + this.refreshDocumentData );
        this.refreshDocumentData++;  
      } 
      
      // console.log( 'dateLastChange ' + dateLastChange + ', ' + dateLastChange.getTime() );
      // console.log( 'tenMinutesAgo ' + tenMinutesAgo + ', ' + tenMinutesAgo.getTime() );
    },
    triggerDocumentRefresh( auftragId ) {
      // console.log( 'Setting up check for fresh documents after 10 seconds for ID #' + auftragId )
      const timeoutMs = 10000;
      let handler = function(){
        // console.log( 'Checking for fresh documents' )
        let config = null;
        if (this.$store.getters["auth/hasToken"]) {
          const token = this.$store.getters["auth/getToken"];
          config = {
            headers: {
              Authorization: `Bearer ${token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          };
        } else {
          config = {
          };
        }
        this.fetchDokumente( auftragId, config );
      }.bind(this);
      setTimeout(handler,timeoutMs);  
    },
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    deleteAuftragConfirmation() {
      this.$bvModal.show( 'deleteAuftragConfirm' );
    },
    confirmedDelete() {
      const id = this.auftragsHeaderData.id;
      this.$store.dispatch('auftragswizard/deleteOrder', id);
      this.auftragDeleted = true;
    },
    auftragEditUrl( auftragNr ) {
      return this.$store.getters.getAllEnvis.URL_EXT_SYSTEM + "/" + this.$store.getters.getSubsite + "/p_auftrag_edit?auftragNr=" + auftragNr;
    },
    auftragDeleteUrl( auftragId ) {
      return this.$store.getters.getAllEnvis.URL_EXT_SYSTEM + "/" + this.$store.getters.getSubsite + "/p_auftrag_delete_erfolg?auftragId=" + auftragId;
    },
    auftragEditWizardUrl(auftragType) {
      if(this.token) {
          router.push({name: 'AuftragsVerwaltung', params: {client: store.getters['getSubsite']}, query: {auftragType: auftragType, tid: this.auftragsHeaderData.trancheId, auftragNr: this.auftragsHeaderData.auftragNr, id: this.auftragsHeaderData.id}});
      } else {
        const a = 'auftragsverwaltung';
        const b = auftragType;

        const c = this.auftragsHeaderData.trancheId;
        const d = this.auftragsHeaderData.auftragNr;
        const e = this.auftragsHeaderData.id;
        router.push({name: 'login', params: {client: store.getters['getSubsite']}, query: { a, b, c, d, e }});
      }
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz;
      }
      return dataItem.fondsname
    },
    fetchHistory( auftragId, config ) {
      axios.get( this.$store.getters.getAllEnvis.API_AUFTRAGHISTORIE.replace('{auftragId}',auftragId), config )
          .then( response => {
            this.auftragsHistoryData = response.data;
            this.loadingHistory = false;
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
              // this.$router.push('/errorPage')
            } else if ( error.response.status === 400 ) {
              console.log("This is a 400 error!")
              this.$router.push('/errorPage')
            } else {
              this.servererror = true;
            }
            this.loadingHistory = false;
            this.error = error;
            return error;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loadingHistory = false;
          })

    },
    fetchDokumente( auftragId, config ) {
      axios.get( this.$store.getters.getAllEnvis.API_AUFTRAGDOKUMENTE.replace('{auftragId}', auftragId), config )
          .then( response => {
            this.documentData = response.data;
            this.docsDownload = !(this.documentData.content === null || this.documentData.content === '');
            if ( !this.initialDocumentCount && this.docsDownload ) {
              this.initialDocumentCount = this.documentData.content.length; 
            }
            if ( this.docsDownload ) {
              this.latestDocumentCount = this.documentData.content.length;
            }
            this.calcDocumentRefresh();
          }, error => {
            this.docsDownload = false;
            this.error = error;
            return error;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })  
    },
    fetchData() {
      if (!this.$store.getters["auth/hasToken"]) {
        this.loadingHeader = false;
        this.loadingHistory = false;
        this.unauthorized = true;
        return;  
      }
      this.loadingHeader = true;
      this.loadingHistory = true;
      const auftragId = this.$route.params.id
      var config = null;
      if (this.$store.getters["auth/hasToken"]) {
        const token = this.$store.getters["auth/getToken"];
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        };
      } else {
        config = {
        };
      }
      axios.get( this.$store.getters.getAllEnvis.API_AUFTRAGHEADER.replace('{auftragId}',auftragId), config )
          .then( response => {
            this.auftragsHeaderData = response.data;
            this.loadingHeader = false;
          }, error => {
            if (error.response.status === 401) {
              this.unauthorized = true;
            }
            else if ( error.response.status === 404 ) {
              this.notfound = true;
              // this.$router.push('/errorPage')
            } else if ( error.response.status === 400 ) {
              console.log("This is a 400 error!")
              this.$router.push('/errorPage')
            } else {
              this.servererror = true;
            }
            this.loadingHeader = false;
            this.error = error;
            return error;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
            this.loadingHeader = false;
          })
      
      this.fetchHistory( auftragId, config )
      this.fetchDokumente( auftragId, config )
      this.fetchNote()
      this.readOnlyDueToBietverfahrenRunning()
    },
    fetchNote() {
      const auftragId = this.$route.params.id
      var config = null;
      if (this.$store.getters["auth/hasToken"]) {
        const token = this.$store.getters["auth/getToken"];
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        };
      } else {
        config = {
        };
      }
      axios.get( this.$store.getters.getAllEnvis.API_USER_AUFTRAGNOTE.replace('{auftragId}', auftragId), config )
          .then( response => {
            this.noteData = response.data;
            this.hasNote = true;
          }, error => {
            if (error.response.status === 404 || error.response.status === 403) {
              this.noteData = null;
              this.hasNote = false;
            } else {
              this.error = error;
              return error;
            }
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })
    },
    readOnlyDueToBietverfahrenRunning() {
      const auftragId = this.$route.params.id
      var config = null;
      if (this.$store.getters["auth/hasToken"]) {
        const token = this.$store.getters["auth/getToken"];
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          },
        };
      } else {
        config = {
        };
      }
      axios.get( this.$store.getters.getAllEnvis.API_BIETVERFAHREN_IN_PROGRESS.replace('{auftragId}', auftragId), config )
          .then( () => {
            this.readOnly = false;
          }, error => {
            this.readOnly = true;
            if (error.response.status !== 451) { // 451 is the expected valid response for "BV is running"
              this.error = error;
              return error;
            }
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    addNote(id) {
      this.objectId = id;
      this.noteData = this.$store.getters["notes/getNoteData"];
      this.$bvModal.show( 'noteDetails' );
    },
    deleteNote(id){
      const payload = {
        objectId: id,
        objectType: 'AUFTRAG',
      }
      this.$store.dispatch('notes/deleteNoteData', payload);
      this.hasNote = false;
    },
    getDownloadLink( dateiObject ) {
      var href = "";
      for(let i = 0; i < dateiObject.links.length; i++){
        var link = dateiObject.links[i];
        if ( link.rel === 'download' ) {
          href = link.href;
        }
      }
      return href;
    },
    download( dateiObject ) {
      const payload = {
        filename: dateiObject.name,
        href: this.getDownloadLink( dateiObject ),
        subsite: this.$store.getters.getSubsite
      }
      dateiObject.downloaded = 'true';
      this.$store.dispatch('downloadFileWithToken', payload );
    },
    formatDateTime(n) {
      if(n != null) {
        // Date
        const date = n.split("T")[0]
        const formattedDate = date.split("-")

        // Time
        const timeString = n.split("T")[1]
        const formattedTime = timeString.split(".")[0]
        const shortenedTime = formattedTime.split(":")

        return formattedDate[2] + "." + formattedDate[1] + "." + formattedDate[0] + " - " + shortenedTime[0] + ":" + shortenedTime[1];
      }
    },
    formatDate( n ) {
      if( n !== null ) {
        // Date
        return n.split(" ")[0]
      }
    },
    setNoteState(noteState) {
      this.hasNote= noteState;
    },
  },
  mounted() {

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if ( "noteDetails" === modalId ) {
        this.fetchNote();
      }
    })

    this.auftragDeleted = false;

    document.onclick = function() {
      if(!this.deleteDone) {
        store.dispatch('auftragswizard/resetDeleteDone');
      }
    }
  },
  mixins: [priceMixin,percentMixin],
}
</script>

<style scoped>

</style>