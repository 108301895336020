<template>
  <div>
    
    <div class="row py-3">
      <div class="col-sm-12">
        <div v-if="hasVermittlunglistError()">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ vermittlunglistError() }}</p>
          </div>
        </div>
        
        <b-table id="vermittlungslistetable" 
            ref="vermittlungslistetable" 
            class="pt-3" 
            v-show="this.vermittlunglistCount !== 0"
            bordered
            striped
            responsive="xl"
            :isBusy.sync="isBusy"
            :items="dataProvider"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :key="submitCount"
            head-variant="dark"
        >

          <template #cell(vermittlungsnr)="data">
            <div class="text-left">
              <router-link class="px-0" :to="{name: 'vermittlungsdetails', params: {client: client, id: data.item.id}}">{{ data.item.vermittlungNr }}</router-link>
            </div>
          </template>

          <template #cell(vermittlungsdatum)="data">
            <div class="text-left">
              {{ reformatDate( data.item.vermittlungsdatum ) }}
            </div>
          </template>

          <template #cell(vermittlungsstatus)="data">
            <div class="text-left">
              {{ data.item.vermittlungsstatus }}
            </div>
          </template>

          <template #cell(vermittlungsstatusSeit)="data">
            <div class="text-left">
              {{ reformatDate( data.item.vermittlungsstatusSeit ) }}
            </div>
          </template>

          <template #cell(nominale)="data">
            <div class="text-left" style="white-space: nowrap">
              {{ formatPrice( data.item.nominale.betrag ) }} {{ data.item.nominale.waehrung }}
            </div>
          </template>

          <template #cell(kurs)="data">
            <div class="text-left">
              {{ formatPercent( data.item.kurs ) }}%
            </div>
          </template>
          
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>
        </b-table>

        <b-pagination
            id="pagination"
            v-model="currentPage"
            :total-rows="vermittlunglistCount"
            :per-page="perPage"
            aria-controls="vermittlungslistetable"
            align="right"
            v-if="vermittlunglistCount > perPage"
        ></b-pagination>

        <div class="justify-content-center" v-if="this.vermittlunglistCount === 0">
          <div class="alert alert-info" role="alert">
            <p>
              Dieser Auftrag wurde nicht vermittelt.
            </p>
          </div>
        </div>
        
      </div>
    </div>
    
  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import dateMixin from "@/mixins/formatDate";
import axios from "axios";

export default {
  name: "VermittlungenOfAuftragListTable",
  components: {},
  props: {
    auftragId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    /* table controls */
    currentPage: 1,
    perPage: 25,
    submitCount: 0,
    vermittlunglistCount: 0,
    isBusy: false,
    error: null,
    
    form: {
      sortproperty: 'VERMITTLUNG_DATUM',
      sortdirection: 'desc',
    },
  }),
  computed: {
    fields() {
      return [
        { key: 'vermittlungsnr', label: 'Nr.', thClass: ["text-left"], tdClass: ["text-left"] },
        { key: 'vermittlungsdatum', label: 'Vermittlungstag', thClass: ["text-left"] },
        { key: 'vermittlungsstatus', label: 'Status', thClass: ["text-left"] },
        { key: 'vermittlungsstatusSeit', label: 'seit', thClass: ["text-left"] },
        { key: 'kurs', label: 'Kurs', thClass: ["text-left"] },
        { key: 'nominale', label: 'Nominale', thClass: ["text-left"] },
      ] 
    },
    client() {
      return this.$route?.params?.client
    },
  },
  methods: {
    hasVermittlunglistError() {
      var status = this.vermittlunglistError();
      return !(status === undefined || status === null || status === '' || status === "" )
    },
    vermittlunglistError() {
      return this.error;
    },
    forceRerender() {
      this.submitCount++;
    },
    dataProvider( ctx, callback ) {
      this.isBusy = true;
      this.error = null;
      const reqParams = {
        auftragId:  this.auftragId,
        page:       ctx.currentPage - 1,
        size:       ctx.perPage,
        sort:       this.form.sortproperty ? this.form.sortproperty : '',
        sortDir:    this.form.sortproperty ? this.form.sortdirection.toUpperCase() : ''
      };
      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      var config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        crossdomain: true,
        params: reqParams,
      };
      axios.get(this.$store.getters.getAllEnvis.API_USER_VERMITTLUNGEN, config)
          .then(response => {
            const items = response.data.content;
            callback( items );
            this.vermittlunglistCount = response.data.page.total_elements;
            this.isBusy = false;
          }, error => {
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.isBusy = false;
            this.error = error;
            callback( [] );
          })
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz; 
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      this.currentPage = 1;
      this.forceRerender();
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
  },
  mixins: [priceMixin,percentMixin,dateMixin],
  mounted() {
    this.$nextTick(function () {
      if ( !this.isBusy ) {
        this.submitCount++;
      }
    });
  },
  watch: {
    $route: 'forceRerender'
  },
  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style type="scss" scoped>
</style>